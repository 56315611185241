<template>
  <div class="grid">
    <div class="col-5" v-if="contrato">
      <div class="card">

        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="Importar CSV" icon="pi pi-upload" class="p-button-success" @click="importarCsv()" />
          </template>
        </Toolbar>

        <div style="width: 100%; overflow: auto;">
          <h6>Acesse aqui o exemplo de arquivo de upload - <a href="/app/front/planilha_exemplo_sci.csv">Clique Aqui</a></h6>
          <hr />

          <!--<a style="cursor:pointer" @click="abrir('planilha-importada')">Histórico de Importação - Clique Aqui</a>-->

        </div>
        <Dialog v-model:visible="modalcsv" :style="{ width: '600px' }" :modal="true"
          header="Importar CSV - Separado por Vírgula ">
          <div class="col-12" style="text-align: center;">
            <!-- <div class="field">
                <span>
                  <div>Mês Referência</div>
                  <InputMask required id="inputmask" mask="99/99/9999" v-model="mes_referencia"></InputMask>
                </span>
              </div> -->
          </div>
          <div class="flex align-items-center justify-content-center">
            <div class="field">

              <FileUpload chooseLabel="Selecionar arquivo csv" invalidFileTypeMessage="Somente arquivo com extenção CSV"
                ref="arquivo" mode="basic" name="arquivo" v-on:change="onChangeFileUpload2()" accept=".csv" />
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="modalcsv = false" />
            <Button label="Enviar Arquivos" icon="pi pi-check" class="p-button-success p-button-sm" id="base1"
              @click="enviarCsv()" disabled="true" />
          </template>
        </Dialog>


      </div>
    </div>

    <div class="col-4" v-if="fatura">
      <div class="card">

        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:end>
            <Button label="Importar Arquivos" icon="pi pi-upload" class="p-button-success" @click="importarCsv()" />
          
           
          </template>
        </Toolbar>

        <div style="width: 100%; overflow: auto;">
          <div class="text-red-500">Obrigatório anexar arquivo zip de faturas junto com o arquivo csv!</div>
<hr/>
          <h6>Acesse aqui o exemplo de <b>CSV</b> para upload - <a href="/app/front/planilha_exemplo.csv">Clique Aqui</a></h6>
          <h6>Acesse aqui o exemplo de <b>ZIP</b> para upload - <a href="/app/front/arquivos.zip">Clique Aqui</a></h6>
          <hr />

          <!--<a style="cursor:pointer" @click="abrir('planilha-importada')">Histórico de Importação - Clique Aqui</a>-->

        </div>
        <Dialog v-model:visible="modalcsv" :style="{ width: '600px' }" :modal="true"
          header="Importar CSV - Separado por Vírgula">

          <div class="grid">
            <!-- <div class="col-12" style="text-align: center;">
              <div class="field">
                <span>
                  <div>Mês Referência</div>
                  <InputMask required id="inputmask" mask="99/99/9999" v-model="mes_referencia"></InputMask>
                </span>
              </div>
            </div> -->
            <div class="col-12" style="text-align: center;">
              <div class="field">
                <FileUpload invalidFileTypeMessage="Somente arquivo com extenção CSV" chooseLabel="Selecionar arquivo csv"
                  ref="arquivo" mode="basic" name="arquivo" v-on:change="onChangeFileUpload()" accept=".csv" />
              </div>
            </div>

            <div class="col-12" style="text-align: center;">
              <div class="field">

                <FileUpload class="warning" chooseLabel="Selecionar arquivo ZIP" ref="arquivo_zip" mode="basic"
                  name="arquivo_zip" v-on:change="onChangeFileUploadZip()" accept=".zip" />
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-sm"
              @click="modalcsv = false" />
            <Button label="Enviar Arquivos" disabled="true" icon="pi pi-check" class="p-button-success p-button-sm"
              id="base1" @click="enviarCsv()" />
          </template>
        </Dialog>


      </div>
    </div>

  </div>
</template>
  
<script>
export default {
  data() {
    return {
      modalcsv: false,
      contrato: false,
      fatura: false,
      modalzip: false,
      modalcsvatual: false,
      arquivoatual: "",
      importar_csv: false,
      importar_zip: false,
      arquivo_zip: "",
      mes_referencia: "",
      usinas: [],
      id_usina: "",
      ano: "",
      mes: "",
      anos: [
        { label: "", value: "" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" }],
      meses: [
        { label: "", value: "" },
        { label: "Janeiro", value: "01" },
        { label: "Fevereiro", value: "02" },
        { label: "Março", value: "03" },
        { label: "Abril", value: "04" },
        { label: "Maio", value: "05" },
        { label: "Junho", value: "06" },
        { label: "Julho", value: "07" },
        { label: "Agosto", value: "08" },
        { label: "Setembro", value: "09" },
        { label: "Outubro", value: "10" },
        { label: "Novembro", value: "11" },
        { label: "Dezembro", value: "12" },
      ],
      columns: null,
      energia: [],
      prioridades: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      arquivo: "",
      arquivo2: "",
      arquivo3: "",
      mostrar_download: false,
      prioridade: "",
      unidades: [],
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      unidade: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      status: 0,
      empresas: [],
      areas: [],
      regionais: [],
      tipos: [],
      estados: [],
      cidades: [],
      id_estado: "",
      id_cidade: "",
      statuses: [
        { label: "Inativo", value: "0" },
        { label: "Ativo", value: "1" },
      ],
    };
  },
  productService: null,

  mounted() {
    //22845207 tipo faturamento = 1 fatura
    //22890835 tipo faturmento = 2 contrato

    let user = JSON.parse(localStorage.getItem("user"));
    //user.tipo_faturamento = "22845207"
    if (user.tipo_faturamento == "22845207") {

      this.contrato = false;
      this.fatura = true;
    }
    else {
      this.contrato = true;
      this.fatura = false;
    }

  },
  methods: {
    abrir(url) {
      this.$router.push(url);
    },
    enviarCsv() {



      let bt = document.getElementById('base1');
      bt.disabled = true;
      bt.innerHTML = "Aguarde..."
      let formData = new FormData();

      let user = JSON.parse(localStorage.getItem("user"));

      if (user.tipo_faturamento == "22845207") {

        this.contrato = false;
        this.fatura = true;
        formData.append("arquivo", this.arquivo);
        formData.append("arquivo_zip", this.arquivo_zip);
      }
      else {
        this.contrato = true;
        this.fatura = false;
        formData.append("arquivo", this.arquivo);
      }

      this.axios
        .post("/planilhas/planilha-fornecedor", formData)
        .then(() => {

          this.$toast.add({
            severity: "success",
            summary: "Sucesso",
            detail: "Planilha Importada com sucesso",
            life: 3000,
          });
          this.modalcsv = false;
          bt.disabled = false;
          bt.innerHTML = "Enviar"
        })
        .catch((response) => {
          
          this.$toast.add({
            severity: "error",
            summary: "Ops!",
            detail: response.response.data.dados,
            life: 9000,
          });
          bt.disabled = false;
          bt.innerHTML = "Enviar"
        });
    },


    onChangeFileUpload() {
      this.arquivo = this.$refs.arquivo.files[0];
      this.importar_csv = true;
      this.validarBtImportarZip();
    },

    onChangeFileUpload2() {
      this.arquivo = this.$refs.arquivo.files[0];
      this.importar_csv = true;
      this.validarBtImportar();
    },


    onChangeFileUploadZip() {
      this.arquivo_zip = this.$refs.arquivo_zip.files[0];
      this.importar_zip = true;
      this.validarBtImportarZip();
    },

    validarBtImportarZip() {

      if (this.importar_csv && this.importar_zip) {
        let bt = document.getElementById('base1');
        bt.disabled = false;
        bt.classList.remove("p-disabled")
      }

    },
    validarBtImportar() {
 
      if (this.importar_csv) {
        let bt = document.getElementById('base1');
        bt.disabled = false;
        bt.classList.remove("p-disabled")
      }

    },

    importarCsv() {
      this.modalcsv = true;
    },
    importarZip() {
      this.modalzip = true;
    },


  },
};
</script>
  
<style>
.tabela {
  margin: 20px 0;
}

.tabela th {
  white-space: nowrap;
  background: #EEE;
  padding: 5px;
}

.p-dialog .p-dialog-header {
  background: #eee !important;
  border-color: var(--teal-500);
  color: #666;
  padding: 10px 20px;
}

.p-dialog .p-dialog-content {
  padding: 50px;
}
</style>
  